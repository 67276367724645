<template>

  <div  v-show="isShow" class="add-model">


    <div class="left-menu">
      <div class="menu-item " @click="changeMenuType(1)" :class="menuType==1?'active':''"><i
          class="iconfont icon-a-41-caidanxuanze"></i> <span>推荐网址</span></div>
      <div class="menu-item" @click="changeMenuType(2)" :class="menuType==2?'active':''"><i
          class="iconfont icon-add"></i>
        <span v-if="!isEditMenu" >添加菜单</span>
        <span v-if="isEditMenu" >编辑菜单</span>
      </div>

    </div>

    <div class="close-icon" @click="closeAddModel">
      <i class="iconfont icon-close"></i>
    </div>

    <div class="main-content">


      <div v-if="menuType==2" class="add-form">

        <div class="add-form-row">
          <span class="label">地址</span>

          <input class="input" v-model="menuInfo.url" type="text" placeholder="https://">

        </div>

        <div class="add-form-row">
          <span class="label">名称</span>
          <input class="input" v-model="menuInfo.name" type="text" placeholder="网站名称">

        </div>
        <div class="add-form-row">
          <span class="label">图标颜色</span>

          <div class="content color-list">

            <div v-for="color in  colorList" @click="selectLogoColor(color)" class="color-item" :style="color"></div>


            <div class="color-item">
              <el-color-picker v-model="menuInfo.logoColor" size="mini"></el-color-picker>

            </div>
          </div>

        </div>

        <div class="add-form-row">
          <span class="label">图标文字</span>
          <input class="input" v-model="menuInfo.logoText" type="text" placeholder="网站名称">

        </div>

        <div class="add-form-row">
          <div class="logo-image" :style="logoColoerStyle()">
            {{ menuInfo.logoText }}
          </div>

        </div>

        <div class="add-form-row">

          <div class="save-btn" @click="saveMenu(0)">保存</div>
          <div v-if="!isEditMenu" class="save-again-btn" @click="saveMenu(1)">保存并继续</div>


        </div>
      </div>

      <div v-if="menuType==1" class="menu-select">

        <div class="search-area">
          <input class="search-input" @input="searchTabList()" @blur="searchTabList()"  v-model="tabSearchName" type="text" placeholder="输入并搜索">
        </div>

        <div class="tab-type-list">
          <div v-for="(tabType,index) in  tabTypeList " @click="changeTabType(index)"
               :class="index==selectTabTypeIndex?'active':''" class="tab-type">{{ tabType.name }}
          </div>

        </div>


        <div class="tab-list">
          <div v-for="tab in  tabList" class="tab-item">
            <div class="link-btn" @click="openTabLink(tab)">
              <i class="iconfont icon-lianjie"></i>
            </div>
            <div v-if="tab.logoType==1" class="logo-image">
              <img src="https://files.codelife.cc/icons/douyin.svg" alt="">
            </div>
            <div v-if="tab.logoType==2"  :style="customLogoStyle(tab)" class="custom-logo">
              {{tab.logoText}}
            </div>

            <div class="tab-content">
              <div class="tab-name">
                {{ tab.name }}
              </div>
              <div class="tab-desc">
                {{ tab.intro }}
              </div>


            </div>
            <div class="add-btn" @click="selectTab(tab)">
              添加
            </div>
          </div>


        </div>

      </div>

    </div>

  </div>

</template>

<script>
import {commonApi} from "@/api/commonApi";

export default {
  name: "addMenu",
  data() {
    return {
      isShow: false,
      colorList: [ //颜色列表
        {background: "rgb(22, 129, 255)"},
        {background: "rgb(251, 190, 35)"},
        {background: "rgb(252, 69, 72)"},
        {background: "rgb(75, 60, 54)"},
        {background: "rgb(125, 172, 104)"},
        {background: "rgb(2, 51, 115)"},
        {background: "rgb(200, 172, 112)"},
        {background: "rgb(55, 33, 40)"},
        {background: "rgb(200, 44, 52)"},
        {background: "rgb(5, 64, 146)"},
        {background: "rgb(163, 221, 185)"},

      ],
      menuInfo: {
        name: "",
        url: "",
        size: "0",
        logo: "",
        logoText: "",
        logoColor: "rgb(22, 129, 255)",
        logoType: 2,


      },
      isEditMenu: false,
      currentMenuIndex: 0,
      currentTabType: 0,
      menuType: 1, //1：推荐菜单 2:添加菜单
      tabTypeList: [],
      selectTabTypeIndex: 0,
      tabList: [],
      tabSearchName:"" //标签搜索名称

    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    currentIndex: {
      default: 0,
      type: Number
    },
    tabType: {
      default: 0,
      type: Number
    },
    editMenuInfo: {
      default: {},
      type: Object
    },
    isEdit: {
      default: false,
      type: Boolean
    },


  },
  watch: {
    show(v) {
      this.isShow = v;
    },
    currentIndex(v) {
      this.currentMenuIndex = v;
    },
    tabType(v) {
      this.currentTabType = v;
    },
    editMenuInfo(v) {
      console.log("菜单信息", v);
      this.menuInfo = v;
    },

    isEdit(v) {
      this.isEditMenu = v;
      if(v){
        this.menuType=2;
      }
    },
  },
  mounted() {
    this.getTabTypeList();
  },
  methods: {
    customLogoStyle(tab){

      return {background:tab.logoColor};
    },
    openTabLink(tab){
      window.open(tab.url,"_blank");
    },
    changeTabType(index) {
      this.selectTabTypeIndex = index;
      this.getTabListByType(this.tabTypeList[this.selectTabTypeIndex].id);
    },
    searchTabList(){
      this.getTabListByType(this.tabTypeList[this.selectTabTypeIndex].id);

    },
    changeMenuType(type) {

      console.log("更改类型", type);
      this.menuType = type;
      if (type == 1) {
        this.getTabTypeList();
      }

    },
    closeAddModel() {
      this.isShow = false;
      this.$emit("close");
    },
    logoColoerStyle() {

      return {background: this.menuInfo.logoColor};
    },
    selectTab(tab){
      this.isEditMenu=false;
      tab.id=null;
      this.menuInfo=tab;
      this.saveMenu(0);
    },
    saveMenu(isGoOn) {

      if (!this.menuInfo.name) {

        this.$message.error("请输入菜单名称");
        return;

      }

      if (!this.menuInfo.url) {

        this.$message.error("请输入菜单地址");
        return;

      }


      if (!this.menuInfo.logoText) {

        this.$message.error("请输入图标文字");
        return;

      }

      if (this.isEditMenu) {
        var editRes = {
          isEdit: true,
          menu: this.menuInfo,
          index: this.currentMenuIndex,
        };

        this.$emit("editSuccess", editRes);
        // this.menuList[this.currentMenuIndex] = this.menuInfo;
      } else {
        // this.menuList.push(this.menuInfo);
        var editRes = {
          isEdit: false,
          menu: this.menuInfo,
          index: this.currentMenuIndex,
        };

        this.$emit("editSuccess", editRes);

      }

      this.closeAddModel();


    },
    selectLogoColor(color) {

      console.log("选择颜色", color);
      this.menuInfo.logoColor = color.background;
    },
    getTabTypeList() {
      commonApi.request("tabType", "getList", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.tabTypeList = res.data;
              // 分页赋值

              this.getTabListByType(this.tabTypeList[this.selectTabTypeIndex].id);

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    getTabListByType(tabType) {

      if (!commonApi.isLogin()) {
        //读取本地缓存
        var tabData = {
          tabTYpe: this.currentTabType,
          menuList: this.menuList
        };
        var tabDataStr = localStorage.getItem("tabData-" + tabType);

        if (tabDataStr) {
          var tabData = JSON.parse(tabDataStr);
          this.menuList = tabData.menuList;
          console.log("读取缓存菜单列表")
          return;
        }

      }


      commonApi.request("tab", "getNewTabList", {tabType: tabType,tabName:this.tabSearchName})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.tabList = res.data;
              // 分页赋值

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

  }
}
</script>

<style lang="scss" scoped>

/**
大屏幕央视
 */
@media (min-width: 1200px) {
  .add-model {

    width: 58%;
    margin: 0 auto;
    left: 20%;
    top: 180px;
  }
}

/**
中屏幕
 */
@media (min-width: 600px) and (max-width: 1199px) {
  .add-model {

    width: 80%;
    margin: 0 auto;
    left: 10%;
    top: 180px;
  }
}


@media (max-width: 599px) {
  .add-model {
    width: 100%;
    margin: 0 auto;
  }
}


.add-model {
  position: absolute;

  box-shadow: #00000080 0 10px 30px;
  z-index: 999;
  border-radius: 8px;
  padding: 5px 4px;
  background-color: rgba(196, 180, 180, 0.3);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(11, 11, 11, .2);
  //top: 0px;
  //left: 400px;
  //width: 800px;
  height: 600px;

  .close-icon {


    text-align: center;

    display: inline-block;
    position: fixed;
    right: 10px;
    top: 10px;

    .icon-close {
      background: rgba(25, 27, 28, 0.5);
      padding: 5px;
      border-radius: 15px;

      color: #ffffff;
    }
  }

  .left-menu {
    width: 16%;

    float: left;


    display: inline-block;

    padding: 20px;


    .menu-item {

      padding: 5px;
      background: rgba(225, 230, 236, 0.5);
      border-radius: 10px;
      text-align: center;
      margin-top: 10px;

      i {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
      }

    }

    .active {

      background: rgba(242, 245, 248, 0.8);


    }

  }

  /* 设置滚动条宽度 */

  .main-content::-webkit-scrollbar {
    width: 5px !important;
    height: 8px !important;
  }

  .main-content::-webkit-scrollbar-thumb {
    background-color: #cdd4ea !important;

  }
  .main-content::-webkit-scrollbar-track  {
    background-color: rgb(255, 255, 255) !important;


  }

  .main-content {
    display: inline-block;

    width: 74%;
    border: 1px solid #f9f9f9;
    //background: rgba(227, 220, 220, 0.5);
    border-radius: 10px;
    height: 100%;

    overflow-y: auto;

    .add-form {
      padding-top: 25px;
      padding-bottom: 25px;

      .add-form-row {

        margin-top: 15px;
        margin-bottom: 15px;

        .label {
          margin-left: 10px;
          margin-right: 10px;
          width: 80px;
          display: inline-block;


        }

        .content {

          display: inline-block;
        }

        .logo-image {

          width: 64px;
          height: 64px;
          background: #42b983;

          border-radius: 15px;

          text-align: center;
          color: #ffffff;
          margin-left: 100px;

          display: flex;
          align-items: center; /* 垂直居中 */
          justify-content: center; /* 水平居中，可选 */
        }


        .color-list {

          .color-item {

            display: inline-block;
            padding: 10px;

            margin-right: 10px;


            border-radius: 10px;
          }

        }

        .input {

          width: 360px;
          height: 25px;
          border-radius: 10px;
          padding-left: 8px;

          border: 1px solid #ffffff;
        }

        .input:focus {
          outline: none;

        }

      }

      .save-btn {

        background: rgb(24, 144, 255);
        color: #ffffff;

        display: inline-block;
        padding: 5px 10px;

        border-radius: 5px;

        margin-left: 100px;

      }

      .save-again-btn {

        background: rgb(240, 241, 245);
        color: rgb(89, 89, 90);
        display: inline-block;

        padding: 5px 10px;

        margin-left: 30px;

        border-radius: 5px;


      }

    }


    .menu-select {

      padding: 10px;

      .search-area {

        padding: 3px;

        .search-input {
          padding: 3px;
          border: 1px solid #ccc;
          border-radius: 6px;

        }

        .search-input:focus {
          outline: none;
        }

      }

      .tab-type-list {
        padding: 5px;

        .tab-type {

          display: inline-block;
          padding: 2px 8px;
          background: #b2a8a8;
          margin-right: 10px;
          font-size: 12px;
          color: #f9f9f9;
          border-radius: 5px;
        }

        .active {
          background: #e14d4d;
        }
      }

      .tab-list {

        .tab-item {
          display: inline-block;
          background: rgba(227, 220, 220, 0.5);
          width: 220px;
          height: 80px;
          padding: 1%;
          border-radius: 10px;
          margin: 1%;


          .logo-image{

            display: inline-block;
            width: 45px;
            height: 45px;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }


          .custom-logo {
            text-align: center;
            //display: flex;
            align-items: center; /* 垂直居中 */
            justify-content: center; /* 水平居中，可选 */
            color: #ffffff;
            width: 45px;
            height: 45px;
            line-height: 45px;
            border-radius: 10px;
            font-size: clamp(8px, 1vw, 12px); /* 文字大小在12px和24px之间，依据容器宽度而变化 */
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;            display: inline-block;
          }


          .link-btn {
            display: inline-block;
            padding: 2px;
            background: rgba(93, 127, 236, 0.8);
            border-radius: 5px;
            font-size: 12px;
            color: #f9f9f9;
            float: right;
          }

          .tab-content {
            display: inline-block;
            vertical-align: top;
            margin-left: 10px;
            width: 140px;

            .tab-name {

              font-size: 14px;
              font-weight: bold;

            }

            .tab-desc {
              color: #2d2b2b;
              margin-top: 2px;

              font-size: 14px;

            }


          }

          .add-btn {
            display: inline-block;
            padding: 2px;
            background: #42b983;
            border-radius: 5px;
            font-size: 12px;
            color: #f9f9f9;
            float: right;
            //margin-top: 50px;
            //margin-left: 10px;
          }

        }
      }

    }
  }
}

</style>