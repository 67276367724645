import axios from 'axios';


axios.defaults.timeout = 10000
// # 配置axios的baseURL
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// 登录请求方法
const loginreq = (method, url, params) => {
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
        traditional: true,
        transformRequest: [
            function(data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};
// 通用公用方法
const req = (method, url, params,isJson) => {
  if (typeof isJson !== "undefined") {
    // 变量已定义

  } else {
    // 变量未定义
    isJson=false;
  }
  let headers={
  };
    var memberStr=  localStorage.getItem('memberInfo');

  if(isJson){
    headers={
      'Content-Type': 'application/json',
        "membertoken":"",
        "shareCode":""
    };

    params=JSON.stringify(params);

  }else {

    headers={
      'Content-Type': 'application/x-www-form-urlencoded',
        "membertoken":"",
        "shareCode":""
    };
  }

    if(memberStr){
        var memberInfo=JSON.parse(memberStr);
        headers.membertoken=memberInfo.token;
    }

    var shareCode=  localStorage.getItem('shareCode');
    if(shareCode){
        headers.shareCode=shareCode;
    }

    return axios({
        method: method,
        url: url,
        headers: headers,
        data: params,
        traditional: true,
        // transformRequest: [
        //     function(data) {
        //         let ret = ''
        //         for (let it in data) {
        //             ret +=
        //                 encodeURIComponent(it) +
        //                 '=' +
        //                 encodeURIComponent(data[it]) +
        //                 '&'
        //         }
        //         return ret
        //     }
        // ]
    }).then(res =>{



      if(res.data.code==-1){
        console.log("登录过期",res);
        localStorage.removeItem("memberInfo");

        //   this.$router.push({path:'/login',query: {}})

      }

      return  res.data;
    });
};

export {
    loginreq,
    req
}
