<template>
  <div v-if="showSearchTypeList" class="search-type-list">
    <div v-for="searchType in  searchTypeList" @click="selectSearchType(searchType)" class="search-type">

      <div class="logo-image">
        <img :src="searchType.logo" alt="">

      </div>

      <div class="logo-label">
        {{ searchType.name }}
      </div>
    </div>
  </div>
</template>

<script>
import {commonApi} from "@/api/commonApi";
export default {
  name: "searchTypeList",
  data() {
    return {
      showSearchTypeList: false,

    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },

  },
  watch: {
    show(v){
      this.showSearchTypeList=v;
      this.getSearchSiteList();
    },

  },
  mounted() {
    this.getSearchSiteList();
  },
  methods: {
    getSearchSiteList() {
      commonApi.request("searchSite", "getList", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.searchTypeList = res.data;
              // 分页赋值

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    } ,
    selectSearchType(searchType) {
      this.searchType = searchType;
      this.showSearchTypeList = false;
      this.$emit("close",searchType);
    },
  }
}
</script>

<style lang="scss" scoped>
.search-type-list {

  display: inline-block;

  width: 575px;
  position: fixed;
  z-index: 1000;
  top: 230px;
  //left: 661px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 20px;
  background: #F1ECECFF;

  border-radius: 20px;

  .search-type {
    display: inline-block;
    text-align: center;
    margin: 5px;

    .logo-image {

      padding: 5px;
      background: #f9f9f9;
      border-radius: 10px;
      width: 40px;
      height: 40px;

      img {
        width: 40px;
        height: 40px;
      }


    }

    .logo-label {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

</style>