<template>

  <div>
    <div v-if="isShow" class="login-model">
      <i class="iconfont icon-close" @click="closeLoginModel()"></i>
      <div class="header">
<!--        <div class="saoma-login " @click="changeLoginType(1)" :class="loginType==1?'active':''">扫码登录</div>-->
        <div class="photo-login" @click="changeLoginType(2)" :class="loginType==2?'active':''">邮箱登录</div>
      </div>

      <div v-if="loginType==1" class="content">

        <div class="logo">
          <div class="wx-logo">
            <img src="https://go.itab.link/login/wechat.svg" alt="">

          </div>
          <div class="wx-text">
            微信登录
          </div>
        </div>

        <div class="login-qrcode">
          <img
              src="https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGK7zwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAySzlvaWNDdURmUkUxaDlKejFDMW0AAgQpEWNmAwQgHAAA"
              alt="">

        </div>

        <div class="login-tip">
          打开微信扫一扫 关注公众号后即可扫码登录
        </div>


      </div>

      <div v-if="loginType==2" class="content">


        <div v-if="!isRegister" class="login-form">
          <div class="email-input">
            <input v-model="loginInfo.email" type="text" placeholder="请输入邮箱">
          </div>

          <div class="password-input">
            <input v-model="loginInfo.password" type="password" placeholder="请输入密码">
          </div>

          <div class="forget-password">忘记密码</div>

          <div class="login-btn" @click="loginByEmail()">
            立即登录
          </div>

          <div class="register">
            还没有账号 <span class="register-btn" @click="showRegister">立即注册</span>
          </div>
        </div>

        <div v-if="isRegister" class="register-form">
          <div class="email-input">
            <input v-model="registerInfo.email" type="text" placeholder="请输入邮箱">
          </div>

<!--          <div class="password-input">-->
<!--            <input v-model="registerInfo.checkCode" class="send-code-input" type="text" placeholder="请输入邮箱验证码">-->
<!--            <span class="send-code-btn" @click="sendMsgCode">{{sendText}}</span>-->
<!--          </div>-->


          <div class="email-input">
            <input v-model="registerInfo.name" type="text" placeholder="请输入昵称">
          </div>

          <div class="password-input">
            <input v-model="registerInfo.password" type="text" placeholder="请输入密码">
          </div>

          <div class="forget-password">忘记密码</div>

          <div class="login-btn" @click="registerByEmail()">
            立即注册
          </div>

          <div class="register">
            已有账号 <span class="register-btn" @click="showLogin">立即登录</span>
          </div>
        </div>


      </div>


    </div>

  </div>

</template>

<script>
import {commonApi} from "@/api/commonApi";

export default {
  name: "loginModel",
  data() {
    return {
      isShow: false,
      loginType: 2, //1扫码登录 2：邮箱登录
      loginInfo: {

        email: "",//邮箱
        password: "",//密码

      },
      registerInfo: {
        name:"",//名称
        email: "",//邮箱
        password: "",//密码
        checkCode: "" //验证码

      },
      isRegister: false,

      //发送秒数
      sendMill:0,
      sendText:"发送验证码",
      timer:null

    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },

  },
  watch: {
    show(v){
      this.isShow=v;
    },

  },
  methods: {
    closeLoginModel() {
      this.isShow = false;
      this.$emit("close");
    },
    changeLoginType(loginType) {

      this.loginType = loginType;
    },
    showRegister() {

      this.isRegister = true;
    },

    showLogin() {

      this.isRegister = false;
    },
    loginByEmail() {
      this.loginInfo.email=this.loginInfo.email.trim();
      if (!this.loginInfo.email) {
        this.$message.error("请输入邮箱");
        return;
      }

      if (!this.loginInfo.password) {
        this.$message.error("请输入密码");
        return;
      }

      commonApi.request("member", "loginByEmail", this.loginInfo)
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值


              this.$message.success(res.msg)

              var memberInfo = res.data;
              // this.memberInfo = memberInfo;



              commonApi.saveLoginInfo(memberInfo);

              this.$emit("loginSuccess",memberInfo);
              this.closeLoginModel();


            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })

    },

    /***
     * 邮箱注册
     */
    registerByEmail() {
      this.registerInfo.email=this.registerInfo.email.trim();
      if (!this.registerInfo.email) {
        this.$message.error("请输入邮箱");
        return;
      }

      if (!this.registerInfo.password) {
        this.$message.error("请输入密码");
        return;
      }

      // if (!this.registerInfo.checkCode) {
      //   this.$message.error("请输入验证码");
      //   return;
      // }
      commonApi.request("member", "registerByEmail", this.registerInfo)
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值

              this.$message.success(res.msg)
              this.loginInfo.email = this.registerInfo.email;

              this.showLogin();
            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    sendMsgCode(){

      this.registerInfo.email=this.registerInfo.email.trim();

      if (!this.registerInfo.email) {
        this.$message.error("请输入邮箱");
        return;
      }

      if(this.sendMill>0){
        this.$message.error("请稍后再发送验证码");
        return;
      }

      commonApi.request("email", "sendEmailCode", {"email":this.registerInfo.email})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值

              this.$message.success(res.msg);

              // 倒计时
              this.sendMill=60;
              this.sendText="已发送("+this.sendMill+")";

              this.timer=setInterval(this.countMill, 1000); // 每秒更新一次时间


            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },
    countMill(){

      this.sendMill=this.sendMill-1;
      this.sendText="已发送("+this.sendMill+")";

      if(this.sendMill<=0){

        clearInterval(this.timer);
        this.sendText="发送验证码";

      }

    }



  }
}
</script>

<style lang="scss"  scoped>
.login-model {

  display: inline-block;
  width: 400px;
  height: 500px;
  border-radius: 10px;

  position: fixed;

  padding: 10px;
  top: 150px;
  background: #f9f9f9;
  left: 0;
  right: 0;
  margin: auto;

  .icon-close {
    float: right;
    margin-right: 10px;
  }

  .header {
    text-align: center;

    .saoma-login {
      display: inline-block;

      width: 40%;
      text-align: center;
      padding: 5px;

      border-bottom: 1px solid #aae3ca;
      color: #aae3ca;
      font-weight: bold;
    }

    .photo-login {
      display: inline-block;

      width: 40%;
      padding: 5px;
      margin-left: 1%;
      text-align: center;
      border-bottom: 1px solid #aae3ca;
      color: #aae3ca;
      font-weight: bold;

    }

    .active {
      border-bottom: 1px solid #42b983;
      color: #42b983;
    }
  }


  .content {
    text-align: center;

    .logo {
      .wx-logo {

        img {
          width: 60px;
          height: 60px;
        }
      }
    }

    .login-qrcode {

      img {
        width: 180px;
        height: 180px;
      }
    }

    .login-tip {

      margin-top: 10px;
    }

    .email-input {
      margin-top: 50px;

      input {
        width: 250px;
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
        border: solid #bfcbd9 1px;
      }

      input:focus {
        outline: none;
      }
    }

    .password-input {

      margin-top: 30px;

      input {
        width: 250px;
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
        border: solid #bfcbd9 1px;
      }

      input:focus {
        outline: none;
      }

      .send-code-input{
        width: 150px;
      }

      .send-code-btn{
        background: #42b983;
        color: #f9f9f9;
        padding: 5px;
        position: relative;
        margin-left: 8px;
        border-radius: 10px;
        width: 80px;
      }
      .send-code-btn:hover{
        background: #7acca8;
      }
    }

    .forget-password {
      float: right;
      margin-right: 60px;
    }

    .login-btn {

      background: #549dc2;
      color: #ffffff;

      padding: 6px;
      border-radius: 10px;
      width: 260px;
      margin: auto;
      margin-top: 60px;

    }

    .register {

      float: right;
      margin-right: 80px;

      .register-btn {
        color: #549dc2;
      }
    }
  }
}
</style>