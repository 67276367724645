<template>
  <div class="container" :style="bgStyle()">


    <!--  左侧菜单  -->
    <div class="main-menu">
      <div class="login-btn"  @click="loginOperate()">
        <div>
          <img
              src="../assets/logo.png"
              alt="">

        </div>

        <div v-if="!memberInfo"   class="login-text">登录</div>
        <div v-if="memberInfo" class="login-text">{{ memberInfo.memberName }}</div>

      </div>


      <div class="operate-btn-list">
        <div class="operate-btn-item " :class="currentTabType==0?'active':'' " @click="changeTabType(0)">
          <div class="logo"><i class="iconfont icon-home-filling"></i></div>
          <div class="label">主页</div>
        </div>

        <div v-for="tabType in tabTypeList" :class="currentTabType==tabType.id?'active':'' "
             @click="changeTabType(tabType.id)" class="operate-btn-item">
          <div class="logo"><i class="iconfont " :class="tabType.icon"></i></div>
          <div class="label">{{ tabType.name }}</div>
        </div>


      </div>

      <div class="bottom-btn-list">
        <div class="bottom-btn" TITLE="设置" @click="showSettingModel()">
          <div class="logo"><i class="iconfont icon-setting"></i></div>
        </div>

        <div v-if="memberInfo" class="bottom-btn" title="退出登录" @click="logout()">
          <div class="logo"><i class="iconfont ">退出</i></div>
        </div>
      </div>
    </div>

    <!--    主区域-->
    <div class="main" @contextmenu.prevent="openCommonCustomMenu" @click="closeMenu">


      <div class="day-info">
        <div class="hour">
          {{ dayInfo.hour }}:{{ dayInfo.minute }}
        </div>

        <div class="date">
          {{ dayInfo.month }}月{{ dayInfo.currentDate }}日 {{ dayInfo.weekDay }}
        </div>

      </div>

      <div class="search">


        <div class="search-logo" @click="showSearchTypeListModel">
          <img :src="searchType.logo" alt="">

          <i class="iconfont icon-a-xiala2"></i>
        </div>


        <input v-model="searchContent" class="input" type="text" @keyup.enter="search" placeholder="请输入内容搜索">


        <i class="iconfont icon-icon-test search-icon" @click="search"></i>
      </div>
      <draggable :list="menuList" class="tab-list-group" @sort="updateMenuSort" ghost-class="ghost">

        <div
            v-for="(menu, index) in menuList"
            :key="index"

            class="tab-item"

            @click="clickMenu(index)"

            :title="menu.name"

            @contextmenu.prevent="openCustomMenu(index,$event)"
        >

          <img v-if="menu.logoType==1" :class="'logo-'+menu.size" :src="menu.logoImage"/>

          <div v-if="menu.logoType==2" class="custom-logo" :style="customLogoStyle(menu)" :class="'logo-'+menu.size">
            {{ menu.logoText }}
          </div>

          <div class="name">
            {{ menu.name }}
          </div>


        </div>

      </draggable>
    </div>


    <div v-if="menuVisible" :style="openCustomMenuStyle()" class="custom-menu">
      <ul>
        <li>布局
          <span class="size-option" @click="changeSize('0')">1X1</span>
          <span class="size-option" @click="changeSize('1')">1X2</span>
          <span class="size-option" @click="changeSize('2')">2X1</span>
          <span class="size-option" @click="changeSize('3')">2X2</span>
          <span class="size-option" @click="changeSize('4')">2X4</span>
        </li>
        <li @click="editModel()"><i class="iconfont icon-bianji"></i> 编辑</li>
        <li @click="deleteMenu()"><i class="iconfont icon-close"></i> 删除</li>
      </ul>
    </div>


    <div v-if="commonMenuVisible" :style="openCommonCustomMenuStyle()" class="custom-menu">
      <ul>
        <li @click="commonAction('add')">添加 <i class="iconfont icon-add"></i></li>
        <li @click="commonAction('editBg')">换壁纸 <i class="iconfont icon-bizhi"></i></li>
        <li @click="commonAction('beifen')">备份 <i class="iconfont icon-beifen"></i></li>
        <li @click="commonAction('setting')">设置 <i class="iconfont icon-setting"></i></li>
      </ul>
    </div>


    <addMenu :show="addModel" @close="closeAddModel" :isEdit="isEditMenu" :editMenuInfo="menuInfo" :currentIndex="currentMenuIndex"
             @editSuccess="editMenuSuccess"></addMenu>


    <searchTypeList :show="showSearchTypeList" @close="closeSearchTypeListModel"></searchTypeList>


    <loginModel :show="isShowLoginModel" @close="closeLoginModel" @loginSuccess="loginSuccess"></loginModel>


    <settingMenu :show="isShowSettingModel" @close="closeSettingModel" @logout="logout"></settingMenu>


<!--    <div class="use-help-btn" @click="toHelpPage"><i data-v-4944b5f0="" class="iconfont icon-guanyu"></i> 使用教程</div>-->

  </div>


</template>

<script>
import Draggable from "vuedraggable";
import {commonApi} from '../api/commonApi';
import editShareInfo from './components/editShareInfo'
import settingMenu from './components/settingMenu'
import loginModel from './components/loginModel'
import searchTypeList from './components/searchTypeList'
import addMenu from './components/addMenu'

export default {
  components: {
    Draggable,
    editShareInfo,
    settingMenu,
    loginModel,
    searchTypeList,
    addMenu
  },
  data() {
    return {
      searchContent: "",
      addProjectTypeDiglog: false,//添加工程类型的弹窗
      templateDialog: false,//模版弹窗
      formLabelAlign: {
        name: "",
        stage: "",
        type: "",
      },
      menuList: [],
      index: 2,
      menuTop: 0,
      menuLeft: 0,
      menuVisible: false,
      commonMenuVisible: false,
      currentMenuIndex: 0,
      addModel: false,
      colorList: [ //颜色列表
        {background: "rgb(22, 129, 255)"},
        {background: "rgb(251, 190, 35)"},
        {background: "rgb(252, 69, 72)"},
        {background: "rgb(75, 60, 54)"},
        {background: "rgb(125, 172, 104)"},
        {background: "rgb(2, 51, 115)"},
        {background: "rgb(200, 172, 112)"},
        {background: "rgb(55, 33, 40)"},
        {background: "rgb(200, 44, 52)"},
        {background: "rgb(5, 64, 146)"},
        {background: "rgb(163, 221, 185)"},

      ],
      menuInfo: {
        name: "",
        url: "",
        size: "0",
        logo: "",
        logoText: "",
        logoColor: "rgb(22, 129, 255)",
        logoType: 2,

      },
      isEditMenu: false,
      //背景图片
      bgImageUrl: "https://fcimage.cbmwz.net/dstab/202406/1717254460636.jpg",
      showSearchTypeList: false,
      searchTypeList: [
        {
          "name": "百度",
          "url": "https://www.baidu.com/s?wd=",
          "logo": "https://files.codelife.cc/itab/search/baidu.svg",
        },


      ],
      searchType: {
        "name": "百度",
        "url": "https://www.baidu.com/s?wd=",
        "logo": "https://files.codelife.cc/itab/search/baidu.svg",
      },
      tabTypeList: [],
      currentTabType: 0,//当前标签类型
      isShowLoginModel: false,

      memberInfo: false, //用户信息

      isShowSettingModel: false,
      isOpenNewTab: true,
      isOpenNewSearch: true,
      shareCode: "",
      share: {},
      dayInfo: {},

    };
  },
  mounted() {
    if (this.$route.params.code) {
      this.shareCode = this.$route.params.code;
      localStorage.setItem("shareCode", this.shareCode);
      console.log("shareCode", this.shareCode);
    }
    this.getTabTypeList();
    var memberInfo = localStorage.getItem("memberInfo");
    if (memberInfo) {
      console.log("登录信息", memberInfo);
      this.memberInfo = JSON.parse(memberInfo);
    }


    var memberConfigInfo = commonApi.getLocalStoreItem("memberConfigInfo");
    if (memberConfigInfo) {
      this.bgImageUrl = memberConfigInfo.memberConfig.backImageUrl;

      if (memberConfigInfo.siteName) {
        this.siteName = memberConfigInfo.siteName;
        document.title = this.siteName;
      }

    } else {
      this.getMemmberConfig();
    }


    //新标签处理
    if (localStorage.getItem("openNewTab")) {
      this.isOpenNewTab = JSON.parse(localStorage.getItem("openNewTab"));

      console.log("isOpenNewTab", this.isOpenNewTab)
    }

    if (localStorage.getItem("openNewSearch")) {
      this.isOpenNewSearch = JSON.parse(localStorage.getItem("openNewSearch"));
    }


  },
  created() {
    this.getTabListByType(this.currentTabType);
    this.getSearchSiteList();
    this.getCurrentTime();
    setInterval(this.getCurrentTime, 60000); // 每秒更新一次时间

  },
  methods: {
    toHelpPage(){
      // console.log("跳转")
      window.open("https://www.dstab.cn/help.html?id=23");
    },

    loginOperate(){

      if(!this.memberInfo){
          this.toLogin();
      }else {
        this.isShowSettingModel=true;
      }
    },


    getCurrentTime() {
      const now = new Date();
      const hour = now.getHours();
      const minute = now.getMinutes();
      const month = now.getMonth() + 1;
      const currentDate = now.getDate() + 1;
      var weekDay = now.getDay(); //// 获取星期，0 (星期日) 到 6 (星期六)
      switch (weekDay) {

        case 0:
          weekDay = "星期日";
          break;
        case 1:
          weekDay = "星期一";
          break;
        case 2:
          weekDay = "星期二";
          break;
        case 3:
          weekDay = "星期三";
          break;
        case 4:
          weekDay = "星期四";
          break;
        case 5:
          weekDay = "星期五";
          break;
        case 6:
          weekDay = "星期六";
          break;
      }
      var dayInfo = {hour: hour, minute: minute, weekDay: weekDay, month: month, currentDate: currentDate};
      this.dayInfo = dayInfo;
      console.log("刷新时间", dayInfo);

    },

    editMenuSuccess(menuRes) {

      if (menuRes.isEdit) {

        this.menuList[menuRes.index] = menuRes.menu;
        this.saveTabListToServer();
      } else {
        this.menuList.push(menuRes.menu);
        this.saveTabListToServer();
      }

    },
    updateMenuSort(){

      this.saveTabListToServer();
    },
    /**
     * 保存菜单列表
     * saveTabMemberData
     */
    saveTabListToServer() {

      if (commonApi.isLogin()) {
        commonApi.request("tab", "saveTabMemberData", {tabType: this.currentTabType, "tabList": this.menuList})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值
                console.log("更新菜单", res.msg);

              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      } else {//保存到本地
        this.saveLocalTabList();

      }


    },

    closeSettingModel() {
      this.isShowSettingModel = false;
    },
    showSettingModel() {
      if (!commonApi.isLogin()) {
        this.isShowLoginModel = true;

      }else {
        this.isShowSettingModel = true;

      }

    },

    logout() {
      commonApi.logout();
      this.memberInfo = false;
    },

    getMemmberConfig() {
      commonApi.request("member", "getMemberConfig", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值

              // this.$message.success(res.msg)
              this.bgImageUrl = res.data.memberConfig.backImageUrl;

              console.log("开始保存缓存")
              commonApi.setLocalStoreItem("memberConfigInfo", res.data, 3600 * 1000);

              // localStorage.setItem("bgImageUrl",this.bgImageUrl);
              if (res.data.siteName) {
                this.siteName = res.data.siteName;
                document.title = this.siteName;
              }

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    closeLoginModel() {
      this.isShowLoginModel = false;
    },
    loginSuccess(memberInfo) {

      console.log("登录成功", memberInfo);
      this.memberInfo = memberInfo;
      this.closeLoginModel();

    },
    toLogin() {
      if (!commonApi.isLogin()) {
        this.isShowLoginModel = true;

      }
    },
    /**
     * 切换标签类ix
     * @param tabType
     */
    changeTabType(tabType) {
      this.currentTabType = tabType;
      this.getTabListByType(this.currentTabType);
    },


    getSearchSiteList() {
      commonApi.request("searchSite", "getList", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.searchTypeList = res.data;
              // 分页赋值

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },
    getTabTypeList() {
      commonApi.request("tabType", "getList", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.tabTypeList = res.data;
              // 分页赋值

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    getNextWallpaper() {
      commonApi.request("wallpaper", "getNextWallpaper", {})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值
              this.bgImageUrl=res.data.url;
              this.saveBgImage(res.data.url);

              //

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    /**
     * 保存壁纸
     * @param bgImageUrl
     */
    saveBgImage(bgImageUrl) {

      if (commonApi.isLogin()) {
        localStorage.setItem("bgImageUrl", bgImageUrl)
        commonApi.request("member", "setBackImageUrl", {imageUrl: bgImageUrl})
            .then(res => {
              this.loading = false
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {

                this.getMemmberConfig();
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      } else {

        localStorage.setItem("bgImageUrl", bgImageUrl)
      }

    },

    getTabListByType(tabType) {

      if (!commonApi.isLogin()) {
        //读取本地缓存
        var tabData = {
          tabTYpe: this.currentTabType,
          menuList: this.menuList
        };
        var tabDataStr = localStorage.getItem("tabData-" + tabType);

        if (tabDataStr) {
          var tabData = JSON.parse(tabDataStr);
          this.menuList = tabData.menuList;
          console.log("读取缓存菜单列表")
          return;
        }

      }


      commonApi.request("tab", "getTabListByType", {tabType: tabType})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.menuList = res.data;
              // 分页赋值

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })
    },

    /**
     * 保存本地
     * 1.没有登录 保存在本地
     * 2.有登录同步到服务器
     *
     */
    saveLocalTabList() {
      var tabData = {
        tabTYpe: this.currentTabType,
        menuList: this.menuList
      };
      localStorage.setItem("tabData-" + this.currentTabType, JSON.stringify(tabData));
    },


    search() {
      var url = this.searchType.url + encodeURIComponent(this.searchContent);
      if (this.isOpenNewSearch) {
        window.open(url, '_blank');
      } else {
        window.open(url, '_self');
      }


    },
    closeSearchTypeListModel(searchType) {
      this.showSearchTypeList = false;
      this.searchType = searchType;
    },
    showSearchTypeListModel(event) {
      this.showSearchTypeList = true;
      console.log("event", event);
      event.stopPropagation();
    },
    bgStyle() {//背景样式

      return {"backgroundImage": `url(${this.bgImageUrl})`};

    },
    customLogoStyle(menu) {
      return {background: menu.logoColor};

    },

    closeAddModel() {
      this.addModel = false;
    },
    commonAction(operate) {
      if (operate == "add") {
        this.addModel = true;
        this.isEditMenu = false;
        this.menuInfo.name = "";
        this.menuInfo.url = "";
        this.menuInfo.logoText = "";
      } else if (operate == "editBg") {//换壁纸

        this.getNextWallpaper();


      }

      this.commonMenuVisible = false;

    },
    clickMenu(index) {
      var url = this.menuList[index].url;

      if (this.isOpenNewTab) {
        window.open(url, '_blank');
      } else {
        window.open(url, '_self');
      }
    },
    /**
     * 改变菜单大小
     * @param size
     */
    changeSize(size) {

      this.menuVisible = false;
      this.menuList[this.currentMenuIndex].size = size;

      console.log("当前菜单列表：", this.menuList)
      this.saveTabListToServer();
    },

    editModel() {
      var menuInfo =this.menuList[this.currentMenuIndex];


      this.menuInfo=menuInfo;
      this.addModel = true;
      this.menuVisible = false;
      this.isEditMenu = true;

    },
    deleteMenu() {
      this.menuList.splice(this.currentMenuIndex, 1);
      this.menuVisible = false;

      this.saveTabListToServer();
    },

    closeMenu() {
      this.menuVisible = false;
      this.commonMenuVisible = false;
      this.showSearchTypeList = false;
      this.isShowSettingModel = false;
    },
    openCustomMenuStyle() {

      return {left: this.menuLeft + 2 + "px", top: this.menuTop + 2 + "px"};
    },
    openCommonCustomMenuStyle() {
      return {left: this.menuLeft + 2 + "px", top: this.menuTop + 2 + "px"};
    },

    openCustomMenu(index, event) {

      this.menuTop = event.clientY;
      this.menuLeft = event.clientX;

      console.log("右键菜单1" + this.menuLeft);
      this.menuVisible = true;
      this.commonMenuVisible = false;
      this.currentMenuIndex = index;
      event.stopPropagation();
    },
    openCommonCustomMenu(event) {

      this.menuTop = event.clientY;
      this.menuLeft = event.clientX;
      console.log("右键菜单2" + this.menuLeft);
      this.commonMenuVisible = true;
      this.menuVisible = false;


    },
    openDialog() {
      this.addProjectTypeDiglog = true;
    },
    addStage() {
      if (this.formLabelAlign.stage) {
        // 使用some方法遍历数组，如果存在相同值则返回true，否则返回false
        const isDuplicate = this.menuList.some((item) => {
          return item.name === this.formLabelAlign.stage;
        });
        if (isDuplicate) {
          // 如果存在相同值，则提示错误并返回false
          this.$message.error("输入重复，请重新输入！");
          return false;
        } else {
          // 否则，将新元素添加到数组中
          this.menuList.push({name: this.formLabelAlign.stage});
        }
      } else {
        this.$message.error("施工阶段不允许为空!!");
      }
    },
    deleteEle(ind) {
      this.menuList.forEach((item, index) => {
        if (ind === index) {
          const index = this.menuList.indexOf(item);
          this.menuList.splice(index, 1);
        }
      });
    },
    resetStage() {
      this.menuList = [];
    },
  }
};
</script>

<style lang="scss" scoped>

@import '//at.alicdn.com/t/c/font_4557862_1l8m9cs2t2x.css';

.container {
  background-image: url("@/assets/image/bg01.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  .main-menu {

    width: 50px;
    height: 100%;
    position: absolute;
    background: rgba(76, 78, 80, 0.8);

    text-align: center;

    .login-btn {

      margin-top: 30px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }

      .login-text {

        font-size: 12px;
        display: inline-block;
        color: #bfcbd9;


      }

    }


    .operate-btn-list {

      margin-top: 70px;


      .active {
        background: #36393b;
      }

      .operate-btn-item {

        //display: inline-block;
        margin-top: 5px;
        margin-bottom: 5px;

        padding-top: 5px;
        padding-bottom: 5x;

        color: rgba(238, 232, 232, 0.6);

        .logo {
          font-size: 32px;
          display: inline-block;

        }

        .label {
          font-size: 14px;
        }

      }

    }

    .bottom-btn-list {

      position: fixed;
      bottom: 10px;
      text-align: center;

      .bottom-btn {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 5px;

        i {
          margin-left: 12px;
          color: #eeeeee;
          font-size: 14px;

        }

        .icon-setting{
          font-size: 18px;
        }

      }

    }

  }


  @media (max-width: 1199px) {
    .main {
      width: 100%;
    }
  }


  @media (min-width: 1200px) {
    .main {
      width: 1200px;
    }
  }

  .main {

    height: 100%;
    margin: auto;


    .day-info {
      text-align: center;

      .hour {

        padding-top: 40px;
        font-weight: bold;
        font-size: 60px;
        color: #fff;

      }

      .date {

        color: #ffffff;
        font-size: 16px;


      }

    }


    .search {


      padding-top: 50px;
      padding-bottom: 40px;
      text-align: center;


      .search-logo {
        background: rgba(220, 223, 225, 0.5);
        display: inline-block;
        position: relative;
        left: 52px;
        top: 5px;
        padding: 10px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        img {
          width: 20px;
          height: 20px;
        }

        i {

          font-size: 12px;
          vertical-align: top;

          display: inline-block;
          position: relative;
          top: 5px;
          left: 2px;
        }
      }

      .input {
        //margin-left: 100px;
        width: 550px;
        height: 40px;
        border-radius: 20px;

        border: 1px solid #ccc;
        margin: auto;
        font-size: 18px;
        padding-left: 58px;
        background: #efebeb;

      }

      .input:focus {

        outline: none;
      }

      .search-icon {
        font-size: 20px;
        position: relative;
        right: 42px;
        top: 3px;
        padding: 10px;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;

      }

      .search-icon:hover {
        background: #d1d7d4;
      }

    }
  }
}

.tab-list-group {


  .tab-item {

    //border: 1px solid #bfcbd9;
    transition: all 1s;

    display: inline-block;
    text-align: center;
    //width: 100px;
    //height: 100px;

    border-radius: 10px;
    float: left;

    margin: 15px;


    .name {

      //color: #071c12;
      font-size: 14px;
      text-align: center;
      color: #f5f2f2;
      margin-top: 5px;
      font-weight: bold;

    }

    .custom-logo {
      text-align: center;
      display: flex;
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中，可选 */
      color: #ffffff;
      font-weight: bold;
    }

    .logo-0 {

      margin-top: 8px;

      width: 64px;
      height: 64px;
      border-radius: 10px;
      font-size: 12px;

    }

    .logo-1 {

      width: 128px;
      height: 64px;
      margin-top: 8px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;
    }

    .logo-2 {

      width: 64px;
      height: 128px;
      margin-top: 8px;
      border-radius: 10px;
      font-size: 18px;
      font-weight: bold;
    }

    .logo-3 {

      width: 128px;
      height: 128px;
      margin-top: 8px;
      border-radius: 10px;
      font-size: 20px;
      font-weight: bold;
    }

    .logo-4 {

      width: 256px;
      height: 128px;
      margin-top: 8px;
      border-radius: 10px;
      font-size: 22px;
      font-weight: bold;
    }

  }

}

.custom-menu {
  position: absolute;

  box-shadow: #00000080 0 10px 30px;
  width: var(--width, 140px);
  z-index: 999;
  border-radius: 8px;
  padding: 5px 4px;
  background-color: #0b0b0bcc;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(11, 11, 11, .2);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {


    transition: background .2s;
    font-size: 12px;
    line-height: 30px;
    color: #fff;
    padding: 0 4px;
    cursor: pointer;
    margin: 4px 0;
    border-radius: 6px;

    .size-option {
      padding: 1px;
      margin: 2px;
    }

    i {
      float: right;
    }

    .size-option:hover {

      background-color: rgba(56, 51, 51, 0.8);

    }
  }

  li:hover {
    background-color: #0b0b0bcc;
    transition: background .2s;

  }
}

.use-help-btn{
  padding: 10px;
  background: #39cb89;
  color: #f9f9f9;
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-radius: 18px;
  z-index: 10;
  font-weight: bold;
  font-size: 14px;
}


</style>