import axios from 'axios';
import {req} from './axiosFun';

/**
 * 公共请求
 * operate：操作
 * params：请求参数
 **/
export const commonApi = {
    request: (model, operate, params) => {
        return req("post", "/api/" + model + "/" + operate, params, true)
    },
    /**
     * 判断是否登录
     * @returns {boolean}
     */
    isLogin() {
        var memberStr = localStorage.getItem("memberInfo");
        if (memberStr) {
            return true;
        } else {
            return false;
        }
    },
    /**
     * 退出登录
     */
    logout() {
        localStorage.removeItem("memberInfo");
        this.memberInfo = false;
        this.isShowSettingModel=false;
    },

    /**
     * 保存登录信息
     * @param memberInfo
     */
    saveLoginInfo(memberInfo){
        localStorage.setItem('memberInfo', JSON.stringify(memberInfo))
    },
    /**
     * 设置本地存储
     * @param key
     * @param data
     * @param time
     */
    setLocalStoreItem(key,data,timeStamp){
        const now = new Date();
        // ttl 是时间To Live的缩写，表示有效期
        const item = {
            value: data,
            expiry: now.getTime() + timeStamp,
        };
        localStorage.setItem(key, JSON.stringify(item));


    },

    /***
     * 获取本地存储
     * @param key
     * @returns {*|null}
     */
    getLocalStoreItem(key){

        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;


    },

}



