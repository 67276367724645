<template>
  <div>


    <div v-if="show" class="setting-model">
      <i class="iconfont icon-close" @click="closeSettingModel"></i>
      <div class="left-menu">

        <!--      <div v-if="memberInfo" class="login-btn">{{ memberInfo.memberName }}</div>-->
        <!--      <div v-if="!memberInfo" @click="toLogin()" class="login-btn">登录/注册</div>-->

        <div class="menu-list">
          <div class="menu-item" @click="changeSettingType(0)" :class="settingType==0?'active':''">
            <i class="iconfont icon-share"></i> 个人资料
          </div>
          <div class="menu-item" @click="changeSettingType(1)" :class="settingType==1?'active':''">
            <i class="iconfont icon-share"></i> 分享
          </div>
          <div class="menu-item " @click="changeSettingType(2)" :class="settingType==2?'active':''">
            <i class="iconfont icon-openl"></i> 打开方式
          </div>

          <div class="menu-item" @click="changeSettingType(3)" :class="settingType==3?'active':''">
            <i class="iconfont icon-guanyu"></i> 关于
          </div>
<!--          <div class="menu-item" @click="toHelpPage" :class="settingType==4?'active':''">-->
<!--            <i class="iconfont icon-guanyu"></i> 使用帮助-->
<!--          </div>-->
          <div class="menu-item" @click="logout" :class="settingType==5?'active':''">
            <i class="iconfont icon-tuichudenglu"></i> 退出
          </div>
        </div>
      </div>

      <div class="main-area">
        <div v-if="settingType==0" class="header">
          <div class="title">
            个人资料
          </div>
          <div class="intro">




          </div>
        </div>
        <div v-if="settingType==1" class="header">
          <div class="title">
            分享
          </div>
          <div class="intro">
            分享给他人使用
          </div>
        </div>

        <div v-if="settingType==2" class="header">
          <div class="title">
            打开方式
          </div>
          <div class="intro">
            设置标签打开方式
          </div>
        </div>

        <div v-if="settingType==3" class="header">
          <div class="title">
            关于
          </div>
          <div class="intro">
            关于我们
          </div>
        </div>
        <div v-if="settingType==0" class="content">

          <div  class="content-item">{{memberInfo.name}} <i class="iconfont icon-bianji" @click="editMemberName"></i> </div>
          <div class="content-item">{{memberInfo.email}}</div>



        </div>

        <div v-if="settingType==1" class="content">

          <div v-for="share in  shareList" class="content-item">

            <span class="site-name">{{ share.siteName }} <span v-if="share.isDefault==1" class="default-text">默认</span></span>
            <span class="site-link">{{ serverUrl }}{{ share.code }} </span>

            <div>
              <span class="edit-btn" @click="editShare(share)">编辑</span>
              <span class="copy-btn" @click="copyShareLink(share)">复制</span>
              <span class="delete-btn" @click="deleteShareLink(share)">删除</span>
              <span class="default-btn" v-if="share.isDefault==0" @click="setDefault(share)">设置默认</span>
            </div>

          </div>

                  <div class="share-btn" @click="createShare">生成分享链接</div>


        </div>

        <div v-if="settingType==2" class="content">
          <div class="content-item">


                <span>
                  新标签打开搜索结果
                </span>
            <span class="switch">
                  <el-switch
                      v-model="isOpenNewSearch"
                      @change="changeOpenSearchMethod"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
              </el-switch>
                </span>
          </div>

          <div class="content-item">

                <span>
                  新标签打开图标
                </span>
            <span class="switch">
                  <el-switch
                      v-model="isOpenNewTab"
                      @change="changeOpenTabMethod"
                      active-color="#13ce66"
                      inactive-color="#ff4949">
              </el-switch>
                </span>

          </div>
        </div>

        <div v-if="settingType==3" class="content">
          关于
        </div>
      </div>

    </div>


    <editShareInfo :show="isShowEditShareModel" :share="share" @close="closeEditShareModel"></editShareInfo>
    <editMemberInfo :show="isShowEditMemberModel" :member="memberInfo" @close="closeMemberModel"></editMemberInfo>
  </div>

</template>

<script>
import {commonApi} from "@/api/commonApi";
import editShareInfo from '../components/editShareInfo'
import editMemberInfo from '../components/editMemberInfo'

import Draggable from "vuedraggable";

export default {
  name: "settingMenu",
  components: {
    editShareInfo,
    editMemberInfo
  },
  data() {
    return {
      isShow: false,
      settingType: 0,
      shareList: [],
      serverUrl: "http://" + window.location.host + "/share/",
      share: {},
      isShowEditShareModel: false,
      isShowEditMemberModel: false,
      isOpenNewTab:true,
      isOpenNewSearch:true,
      memberInfo:{

      }

    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    }

  },
  watch: {
    show(v) {
      this.isShow = v;
      this.getShareList();
      this.getLoginInfo();
    }


  },
  mounted() {
    this.getShareList();

  },
  methods: {
    toHelpPage(){
      // console.log("跳转")
      window.open("https://www.dstab.cn/help.html?id=23");
    },
    editMemberName(){

      this.isShowEditMemberModel=true;

    },

    deleteShareLink(share){
      if (commonApi.isLogin()) {
        commonApi.request("memberShare", "deleteShare", {id:share.id})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值

                this.$message({
                  type: 'success',
                  message: res.msg
                })

                this.getShareList();
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      }else {
        this.$message.error('请先登录！')
      }
    },

    setDefault(share){
      if (commonApi.isLogin()) {
        commonApi.request("memberShare", "setDefault", {id:share.id})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值

                this.$message({
                  type: 'success',
                  message: res.msg
                })

                this.getShareList();
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      }else {
        this.$message.error('请先登录！')
      }
    },

    createShare(){
      if (commonApi.isLogin()) {
        commonApi.request("memberShare", "createShare", {})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值
                console.log("更新菜单", res.msg);

                this.$message({
                  type: 'success',
                  message: res.msg
                })

                this.getShareList();
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      }else {
        this.$message.error('请先登录！')
      }
    },

    copyShareLink(share){
      navigator.clipboard.writeText(this.serverUrl+share.code);
      this.$message.success("复制成功");
    },
    closeEditShareModel() {
      this.isShowEditShareModel = false;
    },
    closeMemberModel(){
      this.isShowEditMemberModel=false;
    },
    editShare(share) {
      console.log("编辑分享")
      this.isShowEditShareModel = true;
      this.share = share;
    },
    getShareList() {
      if (commonApi.isLogin()) {
        commonApi.request("memberShare", "getShareList", {})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值
                console.log("更新菜单", res.msg);

                this.shareList = res.data;
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      }
    },


    getLoginInfo() {
      if (commonApi.isLogin()) {
        commonApi.request("member", "getLoginInfo", {})
            .then(res => {
              this.loading = false
              // console.log("查询数据", res);
              if (res.code != 0) {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              } else {
                // this.menuList=res.data;
                // 分页赋值

                this.memberInfo = res.data;
              }
            })
            .catch(err => {
              this.loading = false
              this.$message.error('网络请求错误，请稍后尝试！')
            })
      }
    },
    changeSettingType(type) {

      console.log("更改设置类型", type)
      this.settingType = type;
      if(type==1){
        this.getShareList();
      }

    },
    changeOpenTabMethod(value) {

      console.log("设置打开新标签", value)

      localStorage.setItem("openNewTab", value);

    },
    changeOpenSearchMethod(value) {
      console.log("设置打开搜索新标签", value)
      localStorage.setItem("openNewSearch", value);
    },
    closeSettingModel() {
      this.isShow = false;

      this.$emit("close");
    },
    logout(){

      this.$emit("logout");
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-model {

  position: fixed;

  right: 0px;
  top: 0px;
  width: 480px;
  height: 100%;
  padding: 10px;
  z-index: 10000;

  background: #ffffff;

  .icon-close {
    float: left;
  }

  .left-menu {
    display: inline-block;
    width: 120px;
    height: 100%;
    float: left;

    .login-btn {
      color: #f6f2f2;
      background: #1b97d5;
      display: inline-block;
      padding: 3px;
      border-radius: 4px;
      margin-top: 40px;
      font-size: 14px;
      margin-left: 10px;


    }

    .menu-list {

      .menu-item {
        color: #071c12;
        padding: 8px;
        margin: 8px;
        font-size: 14px;

        .iconfont {
          background: #071c12;
          color: #f9f9f9;
          padding: 3px;
          border-radius: 4px;
        }

      }

      .active {
        background: #cccccc;
      }

    }

  }

  .main-area {
    display: inline-block;
    width: 340px;
    height: 100%;
    float: left;

    .header {

      padding: 5px;

      .title {

        color: #071c12;
        font-weight: bold;
      }

      .intro {
        margin-top: 5px;
        color: #5e5b5b;
        font-size: 12px;
      }

    }

    .content {

      background: rgba(206, 202, 202, 0.5);
      height: 100%;
      padding-top: 4px;

      //分线按钮
      .share-btn {

        padding: 5px;
        display: inline-block;
        background: #42b983;
        color: #eeeeee;
        margin-top: 10px;
        margin-left: 100px;
        border-radius: 10px;
      }

      .content-item {
        margin: 10px;
        padding: 10px;
        background: #ffffff;
        font-size: 14px;

        .site-name {
          display: block;
          font-weight: bold;
          padding-left: 5px;

          .default-text{
            background: #42b983;
            color: #f9f9f9;
            padding: 2px;
            border-radius: 5px;

            float: right;
          }
        }


        .site-link {
          display: block;
          margin: 2px;
          padding: 4px;
        }

        .switch {
          margin-left: 10px;
        }

        .edit-btn {
          padding: 3px;
          background: #1d9fd7;
          color: #ffffff;
          margin-left: 10px;
        }

        .copy-btn {
          padding: 3px;
          background: #39cb89;
          color: #ffffff;
          margin-left: 10px;
        }


        .delete-btn {
          padding: 3px;
          background: #ef2b42;
          color: #ffffff;
          margin-left: 10px;
        }

        .default-btn{
          padding: 3px;
          background: #560652;
          color: #ffffff;
          margin-left: 10px;
        }
      }


    }
  }

}

</style>