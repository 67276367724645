<template>

  <div v-if="isShow" class="edit-model">
    <i class="iconfont icon-close" @click="closeModel"></i>

    <div class="title">修改个人信息</div>
    <div class="form-row">

      <span class="label ">用户名</span>

      <input type="text" v-model="memberInfo.name"  placeholder="请输入用户名"/>
    </div>
    <div class="form-footer">
      <div class="edit-btn" @click="editMemberInfo">修改</div>
    </div>


  </div>

</template>

<script>
import {commonApi} from '../../api/commonApi';
export default {
  name: "editShareInfo",
  data() {
    return {
      isShow: false,
      memberInfo:{

      }
    }
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    member: {
      default: {},
      type: Object
    }
  },
  watch: {
    show(v){
      this.isShow=v;
    },
    member(v){
      this.memberInfo=v;
    }
  },
  methods: {
    closeModel(){
      this.isShow=false;
      this.$emit("close");
    },
    editMemberInfo(){
      if (!this.memberInfo.name) {
        this.$message.error("请输入用户名称");
        return;
      }

      commonApi.request("member", "updateMemberInfo", {name:this.memberInfo.name})
          .then(res => {
            this.loading = false
            // console.log("查询数据", res);
            if (res.code != 0) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              // 分页赋值


              this.$message.success(res.msg);


              var memberInfo = localStorage.getItem("memberInfo");
              if (memberInfo) {
                var  localmemberInfo = JSON.parse(memberInfo);

                localmemberInfo.memberName=this.memberInfo.name;

                localStorage.setItem("memberInfo",JSON.stringify(localmemberInfo));


                location.reload();

              }

              this.closeModel();

            }
          })
          .catch(err => {
            this.loading = false
            this.$message.error('网络请求错误，请稍后尝试！')
          })

    }

  }
}
</script>

<style lang="scss" scoped>

.edit-model{

  position: fixed;

  left: 0px;
  right: 0px;
  top: 280px;
  margin:0 auto;
  z-index: 10000;
  width: 400px;
  background: rgba(245, 239, 239, 0.92);
  padding: 15px;
  border-radius: 10px;

  .title{

    font-weight: bold;
  }

  .icon-close{
    float: right;
  }

  .form-row{
    margin-top: 20px;
    margin-bottom: 10px;

    .label{
    }
    input{
      margin-left: 10px;
      padding: 2px;
      width: 220px;
    }
    input:focus{
      outline: none;
    }


  }

  .form-footer{
    .edit-btn{
      background: #1d9fd7;

      display: inline-block;
      color: #eeeeee;
      padding:  5px 18px;
      border-radius: 5px;
      margin-top: 10px;
      font-size: 14px;
    }
  }

}

</style>