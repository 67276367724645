import Vue from 'vue';
import Router from 'vue-router';
import index from '@/views/index.vue'; // 假设组件在components文件夹下
import test from '@/views/test.vue'; // 假设组件在components文件夹下

Vue.use(Router);




export default new Router({
    mode:"history",
    routes: [




        {
            path: '/share/:code',
            name: 'share',
            component: index,
            hidden: true,
            meta:{

                title:"电商桌面",
                content:{
                    keywords:"淘宝，拼多多，抖音",
                    description:"一个电商简单桌面",
                }


            }
        },
        {
            path: '/',
            name: 'index',
            hidden: true,
            component: index,
            meta:{

                title:"电商桌面",
                content:{
                    keywords:"淘宝，拼多多，抖音",
                    description:"一个电商简单桌面",
                }


            }
        },
        // 其他路由
    ]
});