<template>

  <div>
    test

  </div>
</template>

<script>
export default {
  name: "test"
}
</script>

<style scoped>

</style>